html, body {
    background-color: #bde9fb;
    font-family: 'Sora', sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    /* overflow-x: hidden;  */
    box-sizing: border-box;
}

.navbar {
    display: flex;
    padding: 30px 50px;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    border-bottom: 1.5px solid black;
}

.navbar-links {
    display: flex;
    gap: 20px;
    margin-left: auto;
}

.nav-link {
    text-decoration: none;
    color: #333;
    font-size: 14px;
    font-weight: 500;
}

.nav-link:hover {
    color: #00856a;
}

.container {
    max-width: 100%;
    padding: 0;
    margin: 0 auto;
}

@media (max-width: 600px) {
    .navbar {
        padding: 15px 20px;
    }
}

.nav-link.active {
    color: #007BFF;
}

.banner {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
}
  
.banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
  
.banner-text {
    position: absolute;
    top: 40%;
    left: 50px;
    transform: translateY(-50%);
    color: white;
    font-size: 36px;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.vet-list {
    display: flex;
    justify-content: center;
    width: 100%;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    max-width: 1200px;
    width: 100%;
    padding: 20px;
}

.card {
    background-color: #E3F2FD;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: calc(33.33% - 20px); /* Adjust for three cards per row */
    max-width: 350px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.card img {
    width: 200px;
    height: auto;
    max-height: 800px;
    object-fit: cover;
    object-position: center;
    margin-top: 20px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.card-content {
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
}

.card-content h3 {
    margin: 5px 0;
    font-size: 1.5rem;
    color: #212121; /* Dark text color */
}

.card-content p {
    margin: 5px 0;
    font-size: 1rem;
    color: #757575; /* Grey text color */
}

.card button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 15px;
}

.card button:hover {
    background-color: #0056b3;
}

.vet-profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.vet-profile-card {
    background-color: #E3F2FD;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: calc(33.33% - 20px); /* Adjust for three cards per row */
    max-width: 350px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.vet-profile-card img {
    width: 200px;
    height: auto;
    max-height: 800px;
    object-fit: cover;
    object-position: center;
    margin-top: 20px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.vet-profile-card-content {
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

/* appointment table */

.table-container {
    margin: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
    font-weight: bold;
}
.table-button {
    text-align: center;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}
  
tr:hover {
    background-color: #f1f1f1;
}
  
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
  
.pagination button {
    background-color: rgb(162, 162, 199);
    border: none;
    color: white;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
}
  
.pagination button:hover {
    background-color: blue;
}

/* appointment details styling */
.apt-details-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.apt-details-heading {
    color: #333;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
}

.apt-details-info {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.apt-details-label {
    font-weight: bold;
    margin-right: 10px;
}

.notes-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.apt-details-textarea {
    width: 95%;
    min-height: 100px;
    padding: 10px;
    /* margin-bottom: 10px; */
    border-radius: 5px;
    border: 1px solid #ddd;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

.apt-details-button {
    background-color: #007BFF;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.apt-details-button:hover {
    background-color: #6683a1;
}

.save-button {
    align-self: center;
    /* width: 40%; */
    /* flex-basis: 48%; */
}

.ai-section {
    padding: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ai-button {
    background-color: #012d5c;
    width: 40%;
    flex-basis: 40%;
    display: inline-block;
    text-decoration: none;
    text-align: center;
}

/* Vet & owner signup & Login styling */
.container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .title {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .input {
    width: 95%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 auto;
    display: block;
    font-size: 16px;
  }
  
  .button:hover {
    background-color: #0056b3;
  }

  /* My Pets styling */
  .pets-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pet-card {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 80%;
    max-width: 400px;
    margin-bottom: 24px;
  }
  
  .pet-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .pet-card h2 {
    color: #2c3e50;
    font-size: 24px;
    margin-bottom: 16px;
    border-bottom: 2px solid #3498db;
    padding-bottom: 8px;
  }
  
  .pet-card p {
    color: #34495e;
    margin: 8px 0;
    font-size: 16px;
  }
  
  .pet-card p:before {
    content: '•';
    color: #3498db;
    margin-right: 8px;
  }
  
  .unauthorized {
    text-align: center;
    color: #e74c3c;
    font-size: 28px;
    width: 80%;
    max-width: 400px;
    padding: 40px;
    background-color: #fdf2f2;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(231, 76, 60, 0.1);
  }
  
  @media (max-width: 768px) {
    .pet-card, .unauthorized {
      width: 90%;
    }
  }

  .vet-profile-bio-container {
    display: flex;
    justify-content: center;
    padding: 2rem 1rem;
    background-color: #f8f9fa;
}

.vet-profile-bio {
    max-width: 800px;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 2rem;
}

.vet-profile-bio-title {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
    border-bottom: 2px solid #007bff;
    padding-bottom: 0.5rem;
}

.vet-profile-bio-text {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
}